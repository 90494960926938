import React, {useContext, useEffect, useState } from 'react'
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from "../components/Footer";


function Esim() {
    const token = sessionStorage.getItem('token');
    const {productData} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)
    const [showNoProductFound, setShowNoProductFound] = useState(false);
    
    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("e-sim"));

    let navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
    
        if (filteredProducts.length === 0) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
            setShowNoProductFound(true);
          }, 3000);
        } else {
          setIsLoading(false);
        }
    
        return () => clearTimeout(timeoutId); 
      }, [filteredProducts]);

    function getPathName(){
        localStorage.setItem("pathName", window.location.pathname)
        navigate("/register")
    }

  return (
    <div>
        <NavBars/>
        <div className='container spacer' >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <h2>E-Sim</h2>
            {!isLoading && showNoProductFound && <h4 className='text-center' >No product found</h4>}
            <div className="row">
                {filteredProducts.map(product => (
                    <div key={product.id} className="col-sm-3">
                        <div className="card mb-3 shadow product border-0">
                                <div className="">
                                    <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                    <div className="card-body">
                                        <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                                        <p className="card-text text-center">{product.description}</p>
                                        <p className="card-text text-center fw-bold">${(product.price * product.duration).toFixed(2)}</p>
                                        <>
                                        {token ? 
                                            <div className='text-center' >
                                                <Link to={{pathname: `/checkout/${product.id}`}} className="btn btn-primary w-50 ">Buy</Link>
                                            </div>
                                            :
                                            <div className='text-center' >
                                                <button  onClick={getPathName} className="btn btn-primary w-50">Buy</button>
                                            </div>
                                        }
                                        </>
                                    </div>
                                </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
        <div className="d-none">
            <Footer/>
        </div>
    </div>
  )
}

export default Esim