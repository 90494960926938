import React, {useContext, useEffect, useState } from 'react'
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Button, TextField, Backdrop, CircularProgress, FormControl, InputLabel, Select, MenuItem, DialogTitle, Dialog, DialogActions, DialogContent,  DialogContentText  } from '@mui/material';
import {useNavigate } from 'react-router-dom';
import { Footer } from "../components/Footer";
import { usePaystackPayment } from 'react-paystack';
import PaymentIcon from '@mui/icons-material/Payment';
import axios from "axios";
import ToastService from "react-material-toast";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

function PowerBank() {
    const token = sessionStorage.getItem('token');
    const {productData, onBuy, baseURL,logout, isLoadingBuy, publicKey, secretKey} = useContext(AuthContext);
    const [customerData, setCustomerData] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showNoProductFound, setShowNoProductFound] = useState(false);
    const [paymentMode, setPaymentMode] = useState("");
    const [service, setService] = useState("");
    const [rented_item, setRentedItem] = useState("");
    const [dataPackage, setDataPackage] = useState("");
    const [ref, setTransRef] = useState("");
    const [status, setPaymentStatus] = useState("");
    const [point, setPoint] = useState("");
    const [open, setOpen] = useState(false);
    const [openCash, setOpenCash] = useState(false);
    const [openPOS, setOpenPOS] = useState(false);
    const [openPoints, setOpenPoints] = useState(false);
    const [openRedeem, setOpenRedeem] = useState(false);
    const [rates, setRates] = useState([]);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isLoadings, setLoadings] = useState(false);
    
    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("power bank"));
    let navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
    
        if (filteredProducts.length === 0) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
            setShowNoProductFound(true);
          }, 3000);
        } else {
          setIsLoading(false);
        }
    
        return () => clearTimeout(timeoutId); 
      }, [filteredProducts]);

    function getPathName(){
        localStorage.setItem("pathName", window.location.pathname)
        navigate("/register")
    }


    useEffect(() => {
        setIsFormFilled(paymentMode !== '');
        // eslint-disable-next-line
    }, [ paymentMode]);

    const getCustomer = async () => {
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };
        try {
          const res = await axios.get(`${baseURL}/customer/`, config);
          if (res.status === 200) {
            setCustomerData(res.data);
          } else {
            console.log(res);
          }
        } catch (err) {
            if(err.message === "Request failed with status code 401"){
                logout()
              }
          console.log(err);
        }
      };

      const getExchangeRates = async () => {
        await fetch("https://v6.exchangerate-api.com/v6/3dbf859c5b891497ef1e519e/pair/GHS/USD", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            setRates(res.conversion_rate);
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const getProductById = async (id) => {
        try {
            const response = await axios.get(`${baseURL}/restsimproduct/${id}`,{
                headers:{
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.status === 201){
                setData(response.data);
                setService(response.data.name);
                setRentedItem(response.data.name);
                setDataPackage(response.data.name);
            }
        } catch (error) {
            console.log(error);
        }
    }

      useEffect(() => {
        if (token) {
            getCustomer();
          }
        getExchangeRates();
        //eslint-disable-next-line
    }, []);
    
    const email = sessionStorage.getItem("userEmail");
    const referralAmount = customerData.referral_point;
    


    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
          setOpen(false);
      };
    
        const handleOpenCash = () => {
            setOpenCash(true);
        };
    
        const handleCloseCash = () => {
            setOpenCash(false);
        };

        const handleOpenPOS = () => {
            setOpenPOS(true);
        };
    
        const handleClosePOS = () => {
            setOpenPOS(false);
        };
    
        const handleOpenRedeem = () => {
            setOpenRedeem(true);
        };
    
        const handleCloseRedeem = () => {
            setOpenRedeem(false);
        };
    
        const handleOpenPoints = () => {
            setOpenPoints(true);
        };
    
        const handleClosePoints = () => {
            setOpenPoints(false);
            handleOpenRedeem();
        };
    
        const handleClosePoints2 = () => {
            setOpenPoints(false);
        };
    
        const handlePaymentMethod = (event) => {
            setPaymentMode(event.target.value);
        };

         // currency convertor function
  

     // other variables
  let payment_method = paymentMode;
  let amount_GHS = 0.0;
  let amount = 0.00;
  let payment_amount = 0.0;
  let payment_status = status;
  let trans_ref = ref;
  let discount;
//   let actualAmount = 0.0;


  function amountPayable() {
    amount = data.price
  }
  amountPayable();
    // actualAmount = amount;


  function calcDiscount(){
    discount = (data.price * (data.discount / 100))
    payment_amount = (amount - discount).toFixed(2)
}

    calcDiscount();
    amount_GHS = (payment_amount / rates).toFixed(2);
    let finalAmount = amount_GHS * 100;

  useEffect(() => {
    setPoint(payment_amount);
    //eslint-disable-next-line
  }, [payment_amount]);

  function afterPayment(event) {
    event.preventDefault();
    if ( payment_amount === "NaN"){
            toast.error("Time and Date fields cannot be empty");
        }else{
    onBuy(
        service,
        rented_item,
        dataPackage,
        payment_method,
        parseFloat(payment_amount),
        payment_status,
        trans_ref,
    );
    }
  }

  function beforeRedeem(event) {
    event.preventDefault();
    if(payment_amount > referralAmount){
        toast.error("You do not have enough referral amount to make purchase");
        handleCloseRedeem();
        setPaymentMode("");
  }else{
    handleRedeem(event);
    afterPayment(event);
  }
}

  const handleRedeem = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }
    setLoadings(true);
  let newData = {
    point: parseInt(point)
  };
    try{
        const res = await axios.post(`${baseURL}/customer/redeem`,newData,config)
        if(res.status === 200){
            toast.success("Points Redeemed Successfully")
            setPoint("");
            handleCloseRedeem();
            setPaymentMode("Referral Amount");
            getCustomer();
        }else{
            toast.error("Something Went Wrong")
        }
    }catch(err){
        console.log(err)
    }
    finally{
        setLoadings(false);
    }
  
}

//Paystack
const config = {
    reference: (new Date()).getTime().toString(),
    email: email,
    amount: finalAmount, 
    publicKey: publicKey,
    secretKey: secretKey,
    currency: 'GHS',
};



const onSuccess = (reference) => {
  setTransRef(reference.reference);
  setPaymentStatus(reference.status);
  handleClose();
};

const onClose = () => {

  console.log('closed')
}

const initializePayment = usePaystackPayment(config);

  return (
    <div>
        <NavBars/>
        <div className='container spacer' >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h2>Power Bank</h2>
            {!isLoading && showNoProductFound && <h4 className='text-center' >No product found</h4>}
            <div className="row">
                {filteredProducts.map(product => (
                    <div key={product.id} className="col-sm-3">
                        <div className="card mb-3 shadow product border-0">
                            <div className="">
                                <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                <div className="card-body">
                                    <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                                    <p className="card-text text-center">{product.description}</p>
                                    <p className="card-text text-center fw-bold">${product.price}</p>
                                    <>
                                    {token ? 
                                        <div className='text-center' >
                                            <button className="btn btn-primary w-50 " onClick={() => {getProductById(product.id)}} data-bs-toggle="modal" data-bs-target="#paymentMethod">Buy</button>
                                        </div>
                                        :
                                        <div className='text-center' >
                                            <button  onClick={getPathName} className="btn btn-primary w-50">Buy</button>
                                        </div>
                                    }
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="d-none">
            <Footer/>
        </div>
        
        <div className="modal fade" id="paymentMethod" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Payment Method</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingBuy}
            >
                    <CircularProgress color="inherit" />
            </Backdrop>
                <form >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paymentMode}
                        label="Select Method of Payment"
                        onChange={handlePaymentMethod}
                        >
                        <MenuItem value="Cash" onClick={handleOpenCash} >Cash</MenuItem>
                        <MenuItem value="POS" onClick={handleOpenPOS} >POS</MenuItem>
                        <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                        <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                        <MenuItem value="Referral Amount" onClick={handleOpenPoints}>Referral Amount</MenuItem>
                        </Select>
                    </FormControl>
                </form>
            </div>
            <div className="modal-footer">
                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                <button type="button" className="btn btn-primary" disabled={!isFormFilled} onClick={(e) => {afterPayment(e);}}>Submit</button>
            </div>
            </div>
        </div>
        </div>

        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogTitle id="alert-dialog-title">
            {"Confirm to Make Payment"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Price: <span className="unit-price">${data.price}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Discount: <span className="unit-price">${discount}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setPaymentMode("");
                handleClose();
            }}>Cancel</Button>
            <Button onClick={()=>{initializePayment(onSuccess, onClose)}} autoFocus>
                Make Payment
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openCash}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogTitle id="alert-dialog-title">
            {"Cash Payment Method"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You have selected Cash as your payment method
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Price: <span className="unit-price">${data.price}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Discount: <span className="unit-price">${discount}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Please click OK below to continue
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setPaymentMode("");
                handleCloseCash();
            }}>Cancel</Button>
            <Button onClick={(e)=>{
                setPaymentMode("Cash");
                handleCloseCash();
            }} autoFocus>
                Ok
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openPOS}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogTitle id="alert-dialog-title">
            {"POS Payment Method"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                    You have selected POS as your payment method
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Price: <span className="unit-price">${data.price}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Discount: <span className="unit-price">${discount}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Please click OK below to continue
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setPaymentMode("");
                handleClosePOS();
            }}>Cancel</Button>
            <Button onClick={(e)=>{
                setPaymentMode("Cash");
                handleClosePOS();
            }} autoFocus>
                Ok
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openPoints}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogTitle id="alert-dialog-title">
            {"Payment with Referral Amount"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                    You have selected Referral Amount as your payment method
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Price: <span className="unit-price">${data.price}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Discount: <span className="unit-price">${discount}</span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Total Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Your Total Referral Amount: <span className="unit-price" > ${referralAmount} </span>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                Please click OK below to continue
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setPaymentMode("");
                handleClosePoints2();
            }}>Cancel</Button>
            <Button onClick={()=>{
                handleClosePoints();
            }} autoFocus>
                Ok
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openRedeem}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose(event, reason)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown = {true}
        >
            <DialogTitle id="alert-dialog-title">
            {"Payment with Referral Amount"}
            </DialogTitle>
            <DialogContent>
            <form className="mt-3" >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoadings}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <TextField
                    type='number'
                    label="Enter Points to Redeem"
                    placeholder='Points to Redeem'
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    required
                    className='mb-3'
                    value={point}
                    onChange={(e) => setPoint(e.target.value)}
                />
                <div className='text-center' >
                    <button  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {beforeRedeem(event)}} > <PaymentIcon/>  Pay</button>
                </div>
            </form>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setPaymentMode("");
                handleCloseRedeem();
            }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default PowerBank