import React, {useContext, useEffect, useState } from 'react'
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer";


function TopUpPackage() {
    const {productData} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)
    
    const products = productData;
    const filteredProducts = products.filter(product => product.name.toLowerCase().includes("top-up"));

    useEffect(() => {
        let timeoutId;
    
        if (filteredProducts.length === 0) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          setIsLoading(false);
        }
    
        return () => clearTimeout(timeoutId); 
      }, [filteredProducts]);

  return (
    <div>
        <NavBars/>
        <div className='container spacer' >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                    <CircularProgress color="inherit" />
            </Backdrop>
            <h2>Top-Up Data Packages</h2>
                <div className="row">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map(product => (
                            <div key={product.id} className="col-sm-3">
                                <div className="card mb-3 shadow product border-0">
                                    <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                    <div className="card-body">
                                        <h6 className="card-title fw-bold text-center">
                                            {product.name.replace(/\s\d+$/, '')}
                                        </h6>
                                        <p className="card-text text-center fw-bold">${product.price.toFixed(2)}</p>
                                        <div className='text-center'>
                                            <Link to={{ pathname: `/top-up-checkout/${product.id}` }} className="btn btn-primary w-50">
                                                Buy
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 text-center">
                            <p className="fw-bold">No Package found</p>
                        </div>
                    )}
                </div>
        </div>
        <div className="">
            <Footer/>
        </div>
    </div>
  )
}

export default TopUpPackage