import React, {useState, useEffect, useRef, useContext} from 'react'
import axios from 'axios';
import ToastService from "react-material-toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from "react-router-dom";
import { usePaystackPayment } from 'react-paystack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import logo from '../img/logo.png';
import ReactToPrint from 'react-to-print';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PaymentIcon from '@mui/icons-material/Payment';
import { AuthContext } from '../context/AuthContext';


const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function Transactions() {
    const {baseURL, publicKey, secretKey} = useContext(AuthContext)
    const token = sessionStorage.getItem("token")
    const [data , setData] = useState([])
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [paymentMode, setPaymentMode] = useState("");
    const [ref, setTransRef] = useState("");
    const [status, setPaymentStatus] = useState("");
    const [point, setPoint] = useState("");
    const [isLoadings, setLoadings] = useState(false);
    const [transId, setTransId] = useState("");
    const [transData, setTransData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const componentRef = useRef();
    const [itemsPerPage, setItemsPerPage] = useState(10);

    let period = 0;


    const getTransactions = async () => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        setLoading(true);
        try{
            const res = await axios.get(`${baseURL}/customer/product/`,config)
            if(res.status === 200){
                setData(res.data)
            }else{
                toast.error("Something Went Wrong")
            }
        }catch(err){
            if (err.response.status === 401) {
                toast.error("Your session has expired, please login");
                sessionStorage.removeItem("token");
                navigate("/login");
            }
            console.log(err)
        }
        finally{
            setLoading(false);
        }
    }

    const getCustomer = async () => {
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };
        setLoading(true);
        try {
          const res = await axios.get(`${baseURL}/customer/`, config);
          if (res.status === 200) {
            setCustomerData(res.data);
          } else {
            console.log(res);
          }
        } catch (err) {
          console.log(err);
        }
        finally {
            setLoading(false);
        }
      };

    useEffect(() => {
        getTransactions()
        getCustomer()
        //eslint-disable-next-line
    }, [])

    const email = customerData.email;
    const referralAmount = customerData.referral_point;


    const [values, setValues] = useState({
        date_of_pickup: "",
        time_of_pickup: "",
        date_of_return: "",
        time_of_return: "",
        service: "",
        rented_item: "",
        // package: ""
    });

    const getTransactionById = async (id) => {
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        setLoading(true);
        try{
            const res = await axios.get(`${baseURL}/customer/product/${id}`,config)
            if(res.status === 200){
                setTransId(id);
                setTransData(res.data);
                setValues({...values, date_of_pickup: res.data.date_of_pickup, time_of_pickup: res.data.time_of_pickup, date_of_return: res.data.date_of_return, time_of_return: res.data.time_of_return, service: res.data.service, rented_item: res.data.rented_item})
            }else{
                toast.error("Something Went Wrong")
            }
        }catch(err){
            console.log(err)
        }
        finally{
            setLoading(false);
        }
    }


    const handlePickupDateChange = (newDate) => {
        setValues({ ...values, date_of_pickup: newDate });
    };

    const handleReturnDateChange = (newDate) => {
        setValues({ ...values, date_of_return: newDate });
    };

    const handlePickupTimeChange = (newTime) => {
        setValues({ ...values, time_of_pickup: newTime });
    };

    const handleReturnTimeChange = (newTime) => {
        setValues({ ...values, time_of_return: newTime }); 
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                // Mobile devices
                setItemsPerPage(4);
            } else {
                // Desktop devices
                setItemsPerPage(8);
            }
        };

        // Initial call and event listener setup
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const sortedData = data.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
    });


    const [currentPage, setCurrentPage] = useState(1);
    
    const calculatePagination = (data, currentPage, itemsPerPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const itemsToShow = data.slice(startIndex, endIndex);
        return itemsToShow;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const itemsToShow = calculatePagination(sortedData, currentPage, itemsPerPage);

    

    // Extension 
    const [open, setOpen] = React.useState(false);
    const [openCash, setOpenCash] = useState(false);
    const [openPoints, setOpenPoints] = useState(false);
    const [openRedeem, setOpenRedeem] = useState(false);
    const [rates, setRates] = useState([]);

  
    //Modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
    
  };
    const handleOpenCash = () => {
        setOpenCash(true);
    };
    const handleCloseCash = () => {
        setOpenCash(false);
    };

    const handleOpenRedeem = () => {
        setOpenRedeem(true);
    };

    const handleCloseRedeem = () => {
        setOpenRedeem(false);
    };

    const handleOpenPoints = () => {
        setOpenPoints(true);
    };

    const handleClosePoints = () => {
        setOpenPoints(false);
        handleOpenRedeem();
    };

    const handleClosePoints2 = () => {
        setOpenPoints(false);
    };

      const handlePaymentMethod = (event) => {
        setPaymentMode(event.target.value);
      };
    
      const getExchangeRates = async () => {
        await fetch("https://v6.exchangerate-api.com/v6/3dbf859c5b891497ef1e519e/pair/GHS/USD", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            setRates(res.conversion_rate);
          })
          .catch((err) => {
            console.log(err);
          });
      };
    

   // other variables
   let payment_method = paymentMode;
   let amount_GHS = 0.0;
   let amount = 0.00;
   let payment_amount = 0.0;
   let payment_status = status;
   let trans_ref = ref;
//    let discount;
   let actualAmount = 0.0;
 
   // Calculation of Amount to be paid
   var basicUnit;
   

   function amountPayable() {
    let arrivalDate = new Date(values.date_of_pickup);
    let departureDate = new Date(values.date_of_return);
    let difference = departureDate - arrivalDate;
    let days = difference / (1000 * 3600 * 24);
    period = days.toFixed(0);
    amount =period <= 7 ? (3.5 * period).toFixed(2) :
            period <= 14 ? (3.0 * period).toFixed(2) :
            period <= 21 ? (2.5 * period).toFixed(2) :
            (1.7 * period).toFixed(2);
  }

  amountPayable();
    actualAmount = amount;

    function calcDiscount(){
        if(period <= 7){
            payment_amount = (amount + 5);
            basicUnit = 3.5;
        }else if(period <= 14){
                payment_amount = (amount + 5);
                basicUnit = 3.0;
        }else if(period <= 21){
                payment_amount = (amount + 5);
                basicUnit = 2.5;
        }else{
                payment_amount = (amount + 5);
                basicUnit = 1.7;
            }
        }

    // payment_amount = amount;
    calcDiscount();
    amount_GHS = (payment_amount / rates).toFixed(2);
    let finalAmount = amount_GHS * 100;


  useEffect(() => {
    getExchangeRates();
    setPoint(payment_amount)
    //eslint-disable-next-line
  }, [payment_amount]);

  const update = async (event) => {
    
    if ( payment_amount === "NaN"){
        toast.error("Time and Date fields cannot be empty");
    }else{
    event.preventDefault();
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    setLoading(true);
    let newData = {
        date_of_pickup: values.date_of_pickup,
        time_of_pickup: values.time_of_pickup,
        date_of_return: values.date_of_return,
        time_of_return: values.time_of_return,
        service: values.service,
        rented_item: values.rented_item,
        // package: values.package,
        payment_method: payment_method,
        payment_amount: payment_amount,
        payment_status: payment_status,
        trans_ref: trans_ref,
        updated_at: new Date()
    };
    try{
        const res = await axios.patch(`${baseURL}/customer/product/${transId}`, newData, config)
        if(res.status === 201){
            toast.success("Package Extended Successfully")
            console.log(res.data)
        }else{
            toast.error("Something Went Wrong")
        }
    }catch(err){
        console.log(err)
    }
    finally{
        setLoading(false);
        handleClose();
        window.location.reload();
    }
    }
  };

  function beforeRedeem(event) {
    event.preventDefault();
    if(payment_amount > referralAmount){
        toast.error("You do not have enough referral amount to make purchase");
        handleCloseRedeem();
        setPaymentMode("");
  }else{
    handleRedeem(event);
    update(event);
  }
}

  const handleRedeem = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }
  setLoadings(true);
  let newData = {
    point: parseInt(point)
  };
  try{
      const res = await axios.post(`${baseURL}/customer/redeem`,newData,config)
      if(res.status === 200){
          toast.success("Points Redeemed Successfully")
          setPoint("");
          handleCloseRedeem();
          setPaymentMode("Referral Amount");
          getCustomer();
      }else{
          toast.error("Something Went Wrong")
      }
  }catch(err){
      console.log(err)
  }
  finally{
      setLoadings(false);
      
  }}


   //Paystack
  const config = {
      reference: (new Date()).getTime().toString(),
      email: email,
      amount: finalAmount, 
      publicKey: publicKey,
      secretKey: secretKey,
      currency: 'GHS',
  };

  

  const onSuccess = (reference) => {
    setTransRef(reference.reference);
    setPaymentStatus(reference.status);
    handleClose();
    console.log(reference);
  };

  const onClose = () => {

    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);



  if (data.length === 0) {
    return (
        <div className='mt-5' >
          <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <h1 className='text-center p-5' >No data to display</h1>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Extend Package Usage</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    
                    <div className="">
                        <form>
                            <div className="input-filed" >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="Date of Arrival"
                                        value={values.date_of_pickup}
                                        onChange={handlePickupDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                                        
                                    />
                                </LocalizationProvider>
                                
                            </div>
                            <div className="input-filed">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Time of Arrival"
                                        value={values.time_of_pickup}
                                        onChange={handlePickupTimeChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="input-filed" >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date of Departure"
                                        value={values.date_of_return}
                                        onChange={handleReturnDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                                
                            </div>
                            <div className="input-filed">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Departure Time"
                                        value={values.time_of_return}
                                        onChange={handleReturnTimeChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="input-filed" >
                                <TextField
                                    label="Product"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={values.service}
                                    onChange={e => setValues({...values, service: e.target.value})}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />

                            </div>
                            
                            <div className="input-filed">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentMode}
                                    label="Select Method of Payment"
                                    onChange={handlePaymentMethod}
                                    >
                                    <MenuItem value="Cash" onClick={handleOpenCash} >Cash</MenuItem>
                                    <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                                    <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                                    <MenuItem value="Points" onClick={handleOpenPoints}>Points</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </form>
                        <div className="input-filed subBtn">
                            <Button variant="contained" color="primary" type="submit" fullWidth 
                            onClick={(event) => {
                                update(event);
                            }
                            }>Extend Package</Button>
                        </div>
                    </div>
                </div>

                </div>
                </div>
            </div>
            </div>
        </div>
      )
  }else {
    return (
        <>  
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            
            <div >
                <h2>My Transactions</h2>
            </div>
                <table className="table table-striped table-hover" style={{ paddingTop: 40 }} >
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemsToShow.map((item, index) => (
                            <tr key={index} style={{ verticalAlign: 'middle' }} >
                                <td>{item.rented_item}</td>
                                <td>{item.updated_at.slice(0, 10)}</td>
                                <td>
                                    {item.rented_item.toLowerCase().includes("wifi") || item.rented_item.toLowerCase().includes("power bank") ? null : (
                                        <IconButton
                                            key={`extend-button-${item.id}`}
                                            onClick={() => {
                                                getTransactionById(item.id);
                                            } }
                                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                        >
                                            <UpdateIcon style={{ color: "green" }} />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        key={`view-button-${item.id}`}
                                        onClick={() => {
                                            getTransactionById(item.id);
                                        } }
                                        data-bs-toggle="modal" data-bs-target="#viewTrans"
                                    >
                                        <ReceiptIcon style={{ color: "blue" }}  />
                                    </IconButton>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="text-center">
                    <button className='btn border-0'  onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <ArrowBackIosIcon/>Previous
                    </button>
                    <button className='btn border-0' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(sortedData.length / itemsPerPage)}>
                       Next <ArrowForwardIosIcon/>
                    </button>
                </div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Extend Package Usage</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    
                    <div className="">
                        <form>
                            <div className="input-filed" >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="Date of Arrival"
                                        value={values.date_of_pickup}
                                        onChange={handlePickupDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required />}
                                        
                                    />
                                </LocalizationProvider>
                                
                            </div>
                            <div className="input-filed">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Time of Arrival"
                                        value={values.time_of_pickup}
                                        onChange={handlePickupTimeChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="input-filed" >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Date of Departure"
                                        value={values.date_of_return}
                                        onChange={handleReturnDateChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                                
                            </div>
                            <div className="input-filed">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        label="Departure Time"
                                        value={values.time_of_return}
                                        onChange={handleReturnTimeChange}
                                        renderInput={(params) => <TextField {...params} fullWidth required/>}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="input-filed" >
                                <TextField
                                    label="Product"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={values.service}
                                    onChange={e => setValues({...values, service: e.target.value})}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />

                            </div>
                            
                            <div className="input-filed">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={paymentMode}
                                    label="Select Method of Payment"
                                    onChange={handlePaymentMethod}
                                    >
                                    <MenuItem value="Cash" onClick={handleOpenCash} >Cash</MenuItem>
                                    <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                                    <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                                    <MenuItem value="Referral Amount" onClick={handleOpenPoints}>Referral Amount</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </form>
                        <div className="input-filed subBtn">
                            <Button variant="contained" color="primary" type="submit" fullWidth 
                            onClick={(event) => {
                                update(event);
                            }
                            }>Extend Package</Button>
                        </div>
                    </div>
                </div>

                </div>
                </div>
            </div>
            </div>
            <div className="modal fade" id="viewTrans" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Receipt</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body" >
                    <div ref={componentRef}>
                        <div className='text-end mb-5 ' >
                            <img src={logo} alt="logo" width={80} />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                            <h3 className='fw-bold' style={{ color: '#00239F' }}  >Total</h3>
                            <h3 className='fw-bold' style={{ color: '#00239F' }} >${transData.payment_amount}</h3>
                        </div>
                        <hr />
                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                            <div>
                                <h5>Paid With</h5>
                                <h5 className='fw-bold' >{transData.payment_method}</h5>
                            </div>
                            <div>
                                <h5>Date</h5>
                                <h5 className='fw-bold' >{new Date(transData.updated_at).toLocaleDateString()}</h5>
                            </div>
                        </div>
                        <hr />
                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                            <div>
                                <h5>Item</h5>
                            </div>
                            <div>
                                <h5>Amount</h5>
                            </div>
                        </div>
                        <hr />
                        <div style={{display: 'flex', justifyContent: 'space-between'}} >
                            <div>
                                <h5 className='fw-bold' >{transData.rented_item} 
                                </h5>
                            </div>
                            <div>
                                <h5 className='fw-bold' >{transData.payment_amount}</h5>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='text-center' >
                        <ReactToPrint 
                            trigger={() => {
                                return <button className='btn btn-primary' >Print</button>
                            }}
                            content={() => componentRef.current}
                            documentTitle='Receipt'
                            pageStyle='@page { size: A5; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }'
                            
                        />
                    </div>
                </div>
                </div>
            </div>
            </div>

            <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm to Make Payment"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${basicUnit}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{period}</span> days
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClose();
                    }}>Cancel</Button>
                    <Button onClick={()=>{
                        initializePayment(onSuccess, onClose)
                        }} autoFocus>
                        Make Payment
                    </Button>
                    </DialogActions>
            </Dialog>

            <Dialog
                    open={openCash}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Cash Payment Method"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected Cash as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${basicUnit}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{period}</span> days
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseCash();
                    }}>Cancel</Button>
                    <Button onClick={(e)=>{
                        setPaymentMode("Cash");
                        handleCloseCash();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
            </Dialog>

            <Dialog
                    open={openPoints}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Payment with Referral Amount"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected Cash as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${basicUnit}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{period}</span> days
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Your Total Referral Amount: <span className="unit-price" > GH₵{referralAmount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClosePoints2();
                    }}>Cancel</Button>
                    <Button onClick={()=>{
                        handleClosePoints();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
            </Dialog>

            <Dialog
                    open={openRedeem}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                    disableEnforceFocus={true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Payment with Referral Amount"}
                    </DialogTitle>
                    <DialogContent>
                    <form className="mt-3" >
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoadings}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <TextField
                            type='number'
                            label="Enter Points to Redeem"
                            placeholder='Points to Redeem'
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            required
                            className='mb-3'
                            value={point}
                            onChange={(e) => setPoint(e.target.value)}
                        />
                        <div className='text-center' >
                            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {beforeRedeem(event)}} > <PaymentIcon/>  Pay</button>
                        </div>
                    </form>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseRedeem();
                    }}>Cancel</Button>
                    </DialogActions>
            </Dialog>
        </>
           
    )};
  }
