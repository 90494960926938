import React, {useState, useContext, useEffect} from "react";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import {AuthContext} from '../context/AuthContext';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ToastService from "react-material-toast";
import { usePaystackPayment } from 'react-paystack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import PaymentIcon from '@mui/icons-material/Payment';
import { useParams } from 'react-router-dom';
import NavBars from "../components/NavBars";

// import bg from "../img/bg2.png";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export var productDiscount;


export const Onboarding = () => {  
const token = sessionStorage.getItem("token")
const { id } = useParams();
const product_id = id;
const [date_of_pickup, setArrivalDate] = useState();
const [time_of_pickup, setArrivalTime] = useState();
const [date_of_return, setDepartureDate] = useState();
const [time_of_return, setDepartureTime] = useState();
const [service, setService] = useState("");
const [rented_item, setRentedItem] = useState("");
const [dataPackage, setDataPackage] = useState("");
const [paymentMode, setPaymentMode] = useState("");
const [ref, setTransRef] = useState("");
const [status, setPaymentStatus] = useState("");
const [point, setPoint] = useState("");
const {onBoardClient, isLoading, baseURL, logout, publicKey, secretKey} = useContext(AuthContext);
const [open, setOpen] = React.useState(false);
const [openCash, setOpenCash] = useState(false);
const [openPOS, setOpenPOS] = useState(false);
const [openPoints, setOpenPoints] = useState(false);
const [openRedeem, setOpenRedeem] = useState(false);
const [rates, setRates] = useState([]);
const [isFormFilled, setIsFormFilled] = useState(false);
const [isLoadings, setLoadings] = useState(false);
const [customerData, setCustomerData] = useState([]);
const [productData, setProductData] = useState([]);


const getProductById = async () => {
    try {
        const response = await axios.get(`${baseURL}/restsimproduct/${product_id}`,{
            headers:{
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
        if (response.status === 200){
            setProductData(response.data);
        }
    } catch (error) {
        console.log(error);
    }
}


let period = 0;
useEffect(() => {
    setIsFormFilled(date_of_pickup !== '' && time_of_pickup !== '' && date_of_return !== '' && time_of_return !== '' && service !== '' && rented_item !== '' && dataPackage !== '' && paymentMode !== '');
    setService(productData.name)
    setRentedItem(productData.name)
    setDataPackage(productData.name)
    // eslint-disable-next-line
}, [date_of_pickup, time_of_pickup, date_of_return, time_of_return, service, rented_item, dataPackage, paymentMode, period]);


const getCustomer = async () => {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
    try {
      const res = await axios.get(`${baseURL}/customer/`, config);
      if (res.status === 200) {
        setCustomerData(res.data);
      } else {
        console.log(res);
      }
    } catch (err) {
        if(err.message === "Request failed with status code 401"){
            logout()
          }
      console.log(err);
    }
  };

useEffect(() => {
    getCustomer();
    getProductById();
    //eslint-disable-next-line
}, []);

const email = customerData.email;
const referralAmount = customerData.referral_point;


//Modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

    const handleOpenCash = () => {
        setOpenCash(true);
    };

    const handleCloseCash = () => {
        setOpenCash(false);
    };

    const handleOpenPOS = () => {
        setOpenPOS(true);
    };

    const handleClosePOS = () => {
        setOpenPOS(false);
    };

    const handleOpenRedeem = () => {
        setOpenRedeem(true);
    };

    const handleCloseRedeem = () => {
        setOpenRedeem(false);
    };

    const handleOpenPoints = () => {
        setOpenPoints(true);
    };

    const handleClosePoints = () => {
        setOpenPoints(false);
        handleOpenRedeem();
    };

    const handleClosePoints2 = () => {
        setOpenPoints(false);
    };

//Modal

  const handlePaymentMethod = (event) => {
    setPaymentMode(event.target.value);
  };

  const handleArrivalDate = (event) => {
    setArrivalDate(event);
  };


  const handleArrivalTime = (event) => {
    setArrivalTime(event);
  };

  const handleDepartureDate = (event) => {
    setDepartureDate(event);
  };

  const handleDepartureTime = (event) => {
    setDepartureTime(event);
  };

  

    // currency convertor function
  const getExchangeRates = async () => {
    await fetch("https://v6.exchangerate-api.com/v6/3dbf859c5b891497ef1e519e/pair/GHS/USD", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setRates(res.conversion_rate);
      })
      .catch((err) => {
        console.log(err);
      });
  };


   // other variables
  let payment_method = paymentMode;
  let amount_GHS = 0.0;
  let amount = 0.00;
  let payment_amount = 0.0;
  let payment_status = status;
  let trans_ref = ref;
  let discount;
  let actualAmount = 0.0;

  function amountPayable() {
    amount =productData.duration <= 7 ? (productData.price * productData.duration).toFixed(2) :
            productData.duration <= 14 ? (productData.price * productData.duration).toFixed(2) :
            productData.duration <= 21 ? (productData.price * productData.duration).toFixed(2) :
            (productData.price * productData.duration).toFixed(2);
  }
  amountPayable();
    actualAmount = amount;


  function calcDiscount(){
    if(productData.duration <= 7){
        discount = ((productData.price * (productData.discount / 100)) * productData.duration).toFixed(2);
        payment_amount = ((amount - discount)).toFixed(2);
        productDiscount = productData.discount;
    }else if(productData.duration <= 14){
            discount = ((productData.price * (productData.discount / 100)) * productData.duration).toFixed(2);
            payment_amount = ((amount - discount)).toFixed(2);
            productDiscount = productData.discount;
    }else if(productData.duration <= 21){
            discount = ((productData.price * (productData.discount / 100)) * productData.duration).toFixed(2);
            payment_amount = ((amount - discount)).toFixed(2);
            productDiscount = productData.discount;
    }else{
            discount = ((productData.price * (productData.discount / 100)) * productData.duration).toFixed(2);
            payment_amount = ((amount - discount)).toFixed(2);
            productDiscount = productData.discount;
        }
}

    calcDiscount();
    amount_GHS = (payment_amount / rates).toFixed(2);
    let finalAmount = amount_GHS * 100;

  useEffect(() => {
    getExchangeRates();
    setPoint(payment_amount);
    //eslint-disable-next-line
  }, [payment_amount]);

  
  function afterPayment(event) {
    event.preventDefault();
    if ( payment_amount === "NaN"){
            toast.error("Time and Date fields cannot be empty");
        }else{
    onBoardClient(
      date_of_pickup,
      date_of_return,
      time_of_pickup,
      time_of_return,
      service,
      rented_item,
      dataPackage,
      payment_method,
      parseFloat(payment_amount),
      payment_status,
      trans_ref,
      event
    );
    }
  }

  function beforeRedeem(event) {
    event.preventDefault();
    if(payment_amount > referralAmount){
        toast.error("You do not have enough referral amount to make purchase");
        handleCloseRedeem();
        setPaymentMode("");
  }else{
    handleRedeem(event);
    afterPayment(event);
  }
}

  const handleRedeem = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }
    setLoadings(true);
  let newData = {
    point: parseInt(point)
  };
    try{
        const res = await axios.post(`${baseURL}/customer/redeem`,newData,config)
        if(res.status === 200){
            toast.success("Points Redeemed Successfully")
            setPoint("");
            handleCloseRedeem();
            setPaymentMode("Referral Amount");
            getCustomer();
        }else{
            toast.error("Something Went Wrong")
        }
    }catch(err){
        console.log(err)
    }
    finally{
        setLoadings(false);
    }
  
}

   //Paystack
  const config = {
      reference: (new Date()).getTime().toString(),
      email: email,
      amount: finalAmount, 
      publicKey: publicKey,
      secretKey: secretKey,
      currency: 'GHS',
  };

  

  const onSuccess = (reference) => {
    setTransRef(reference.reference);
    setPaymentStatus(reference.status);
    handleClose();
  };

  const onClose = () => {

    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);

  return (
    <div>
        <NavBars/>
    <div className="main-container">
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        <header className="mt-5 spacerOB" >
        </header>
        <div className="form-container">
            <form>
                <div className="input-filed" >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker
                            label="Date of Arrival"
                            value={date_of_pickup}
                             onChange={handleArrivalDate}
                            renderInput={(params) => <TextField {...params} fullWidth required />}
                            
                        />
                    </LocalizationProvider>
                    
                </div>
                <div className="input-filed">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Time of Arrival"
                            value={time_of_pickup}
                            onChange={handleArrivalTime}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                </div>
                <div className="input-filed" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Date of Departure"
                            value={date_of_return}
                             onChange={handleDepartureDate}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                    
                </div>
                <div className="input-filed">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Departure Time"
                            value={time_of_return}
                            onChange={handleDepartureTime}
                            renderInput={(params) => <TextField {...params} fullWidth required/>}
                        />
                    </LocalizationProvider>
                </div>
                
                <div className="input-filed">
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" >Select Method of Payment</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={paymentMode}
                            label="Select Method of Payment"
                            onChange={handlePaymentMethod}
                            >
                            <MenuItem value="Cash" onClick={handleOpenCash} >Cash</MenuItem>
                            <MenuItem value="POS" onClick={handleOpenPOS} >POS</MenuItem>
                            <MenuItem value="Card" onClick={handleClickOpen} >Card</MenuItem>
                            <MenuItem value="Mobile Money" onClick={handleClickOpen}>Mobile Money</MenuItem>
                            <MenuItem value="Referral Amount" onClick={handleOpenPoints}>Referral Amount</MenuItem>
                            </Select>
                        </FormControl>
                </div>
            </form>
            <div className="input-filed subBtn">
                <Button disabled={!isFormFilled}  variant="contained" color="primary" type="submit" fullWidth 
                onClick={(e) => {
                    afterPayment(e);
                }
                }>Submit</Button>
            </div>
                
        </div>
        {/* Modal  */}
                <Dialog
                    open={open}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Confirm to Make Payment"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${productData.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{productData.duration}</span> days
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span> 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClose();
                    }}>Cancel</Button>
                    <Button onClick={()=>{initializePayment(onSuccess, onClose)}} autoFocus>
                        Make Payment
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openCash}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Cash Payment Method"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected Cash as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${productData.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{productData.duration}</span> days 
                    </DialogContentText>
                    {/* <DialogContentText id="alert-dialog-description">
                    An amount of <span className="unit-price">$5.00</span> is included as a holding fee, which will be refunded once you return the rented item.
                    </DialogContentText> */}
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount Dollars: <span className="amount" > ${payment_amount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseCash();
                    }}>Cancel</Button>
                    <Button onClick={(e)=>{
                        setPaymentMode("Cash");
                        handleCloseCash();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openPOS}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"POS Payment Method"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected POS as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${productData.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{productData.duration}</span> days 
                    </DialogContentText>
                    {/* <DialogContentText id="alert-dialog-description">
                    An amount of <span className="unit-price">$5.00</span> is included as a holding fee, which will be refunded once you return the rented item.
                    </DialogContentText> */}
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > ${actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClosePOS();
                    }}>Cancel</Button>
                    <Button onClick={(e)=>{
                        setPaymentMode("Cash");
                        handleClosePOS();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openPoints}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Payment with Referral Amount"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                         You have selected Cash as your payment method
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Unit price: <span className="unit-price">${productData.price}</span> per day
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Period: <span className="period" >{productData.duration}</span> days 
                    </DialogContentText>
                    {/* <DialogContentText id="alert-dialog-description">
                    An amount of <span className="unit-price">$5.00</span> is included as a holding fee, which will be refunded once you return the rented item.
                    </DialogContentText> */}
                    <DialogContentText id="alert-dialog-description">
                        Actual Amount: <span className="unit-price" > GH₵{actualAmount} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Discount: <span className="unit-price">${discount}</span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Cedis: <span className="amount" > GH₵{amount_GHS} </span> 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Amount in Dollars: <span className="amount" > ${payment_amount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Your Total Referral Amount: <span className="unit-price" > ${referralAmount} </span>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Please click OK below to continue
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleClosePoints2();
                    }}>Cancel</Button>
                    <Button onClick={()=>{
                        handleClosePoints();
                    }} autoFocus>
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openRedeem}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onClose(event, reason)
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableEscapeKeyDown = {true}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Payment with Referral Amount"}
                    </DialogTitle>
                    <DialogContent>
                    <form className="mt-3" >
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoadings}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <TextField
                            type='number'
                            label="Enter Points to Redeem"
                            placeholder='Points to Redeem'
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            required
                            className='mb-3'
                            value={point}
                            onChange={(e) => setPoint(e.target.value)}
                        />
                        <div className='text-center' >
                            <button  type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {beforeRedeem(event)}} > <PaymentIcon/>  Pay</button>
                        </div>
                    </form>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => {
                        setPaymentMode("");
                        handleCloseRedeem();
                    }}>Cancel</Button>
                    </DialogActions>
                </Dialog>
    </div>
    </div>
    
    );
};