import React,{useContext} from 'react';
import { AuthContext } from '../context/AuthContext';
import { Footer } from '../components/Footer';
import NavBars from '../components/NavBars';

function QrCodeInstall() {
    const {qrcode} = useContext(AuthContext)
  return (
    <div>
        <NavBars/>
        <div className='container spacer' >
            <div className=' py-5' >
                    <div className='text-center'>
                        <h3>Thank you for using our service.</h3>
                        <h4>The QR-Code below can be used to download a new profile to your device</h4>
                        
                    </div>
                    <div className='d-flex justify-content-center mb-4' >
                        <div>
                            <div className='qr-img'>
                                <img src={qrcode} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div>
                            <h4 className='text-decoration-underline text-center' >Qr-Code Scanning Guide</h4>
                            <h5 className='text-center mb-3'>Connect to a WiFi and follow the steps below</h5>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='w-100' >
                                    <h5 className='text-decoration-underline'>Samsung</h5>
                                    <ul>
                                        <li>Go to Settings on your device's menu and then to Connection</li>
                                        <li>Select SIM Card Manager</li>
                                        <li>Select Add mobile plan</li>
                                        <li>Scan your QR code</li>
                                    </ul>
                                </div>
                                <div className='w-100'>
                                    <h5 className='text-decoration-underline'>iPhone</h5>
                                    <ul>
                                        <li>Go to Settings&gt; Cellular Data / Mobile Data</li>
                                        <li>Tap add a cellular plan/ Mobile Data Plan</li>
                                        <li>Select Add mobile plan</li>
                                        <li>Scan your QR code</li>
                                    </ul>
                                </div>
                                <div className='w-100'>
                                    <h5 className='text-decoration-underline'>Google Pixel</h5>
                                    <ul>
                                        <li>From the settings go to Network &amp; internet and select Mobile Network</li>
                                        <li>Expand Advanced then press Carrier and Add Carrier</li>
                                        <li>Scan your QR code</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div className="">
            <Footer/>
        </div>
    </div>
  )
}

export default QrCodeInstall