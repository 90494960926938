import React, {useContext, useEffect, useState } from 'react'
import NavBars from "../components/NavBars";
import { AuthContext } from '../context/AuthContext';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from "../components/Footer";


function SelfServiceSimCard() {
  const {productData} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showNoProductFound, setShowNoProductFound] = useState(false);
  
  const products = productData;
  const filteredProducts = products.filter(product => product.name.toLowerCase().includes("sim card"))

useEffect(() => {
    let timeoutId;

    if (filteredProducts.length === 0) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
        setShowNoProductFound(true);
      }, 3000);
    } else {
      setIsLoading(false);
    }

    return () => clearTimeout(timeoutId); 
  }, [filteredProducts]);

  return (
    <div>
        <NavBars/>
        <div className='container spacer' >
            <h2>Sim Cards</h2>
            {!isLoading && showNoProductFound && <h4 className='text-center' >No product found</h4>}
            <div className="row">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {filteredProducts.map(product => (
                    <div key={product.id} className="col-sm-3">
                        <div className="card mb-3 shadow product border-0">
                            <div className="">
                                <img src={product.image} className="img-fluid p-2" alt={product.name} />
                                <div className="card-body">
                                    <h6 className="card-title fw-bold text-center">{product.name.replace(/\s\d+$/, '')}</h6>
                                    <p className="card-text text-center">{product.description}</p>
                                    <p className="card-text text-center fw-bold">${(product.price * product.duration).toFixed(2)}</p>
                                    <div className='text-center' >
                                        <Link to={{pathname: `/selfservice/checkout/${product.id}`}} className="btn btn-primary w-50 ">Buy</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="d-none">
            <Footer/>
        </div>
    </div>
  )
}

export default SelfServiceSimCard